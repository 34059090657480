import { RouteAuthContext } from '@/_shared/router/types';
import componentBaseOnFT from '@/_shared/router/ComponentHelper';
import ClientTypesMain from '@/client_types/ClientTypesMain.vue';
import IntegrationsMain from '@/integrations/IntegrationsMain.vue';
import NotificationsTopBar from '@/notifications/components/NotificationsTopBar.vue';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import DataRetentionLeftPanel from '@/data_retention/DataRetentionLeftPanel.vue';
import EventOverviewLeftPanel from '@/event_overview/EventOverviewLeftPanel.vue';
import BaseEmptyView from '@/_shared/components/routing/BaseEmptyView.vue';
import DeletedClients from '@/data_retention/DeletedClients.vue';
import RetentionReport from '@/data_retention/RetentionReport.vue';
import ReportNew from '@/reports/ReportNew.vue';
import ReportList from '@/reports/ReportList.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import PendingDeletions from '@/data_retention/PendingDeletions.vue';
import use from '@/_shared/compositionApi';
import { providedServicesStore } from '@/_shared/store/providedServices';
import useReportStore from '@/_shared/store/reports';
import { RouteLocationNormalized } from 'vue-router';
import { DeepPartial } from '@/_shared/types/extended';
import { Report } from '@/_shared/types/report';
import woundMultiPhotoReportParams from '@/_shared/router/helpers/routesAdminHelper';
import useDataRetentionStore from '../store/dataRetention';

export const adminRoutes = [
  {
    path: '/client_types',
    name: 'admin.clientTypes',
    component: ClientTypesMain,
  },
  {
    path: '/integrations',
    name: 'admin.integrations',
    component: IntegrationsMain,
  },
  {
    path: '/data_retention',
    components: {
      TopBar: BaseEmptyView,
    },
    beforeEnter: async () => {
      useDataRetentionStore().reset();
      await loadModuleLanguageFileAsync('data_retention');
      useDataRetentionStore().fetchInitialData();
      useDataRetentionStore().fetchDeletedClients();
    },
    children: [
      {
        path: '',
        components: {
          SideBar: DataRetentionLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'admin.data_retention',
            component: BasePlaceHolderView,
          },
          {
            path: 'pending',
            name: 'admin.data_retention.pending',
            component: PendingDeletions,
          },
          {
            path: 'report',
            name: 'admin.data_retention.report',
            component: RetentionReport,
          },
          {
            path: 'deleted',
            name: 'admin.data_retention.deleted',
            component: DeletedClients,
          },
        ],
      },
    ],
  },
  {
    path: '/event_overview',
    components: {
      TopBar: BaseEmptyView,
    },
    children: [
      {
        path: '',
        name: 'admin.eventOverview',
        components: {
          SideBar: EventOverviewLeftPanel,
        },
      },
    ],
  },
  {
    path: '/administration',
    component: BasePlaceHolderView,
    meta: { isAngular: true },
    children: [
      {
        path: 'group_services',
        name: 'admin.groupServices',
        component: BasePlaceHolderView,
      },
      {
        path: 'services',
        name: 'admin.services',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/reports',
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        name: 'admin.reports',
        component: async () => componentBaseOnFT('vueReports', ReportList),
        beforeEnter: async () => {
          await useReportStore().fetchAll();
        },
      },
      {
        path: 'new',
        name: 'admin.reports.new',
        component: async () => componentBaseOnFT('vueReports', ReportNew),
        beforeEnter: async (to: RouteLocationNormalized) => {
          const { carerStore } = use.store.carers();
          const { clientStore } = use.store.clients();
          await carerStore.loadCarersForReports();
          await clientStore.loadClientsForReports();
          await providedServicesStore.initialize();
          if (to.query.templateId) {
            useReportStore().useReportAsTemplate(Number(to.query.templateId));
          } else if (to.query.instanceId && to.query.clientId) {
            const customParams: DeepPartial<Report> = woundMultiPhotoReportParams(to.query.clientId as string, to.query.instanceId as string);
            useReportStore().buildNewCustomReport('WoundMultiPhotoReport', customParams);
          } else {
            useReportStore().buildNewReport('ClientInteractionsReport');
          }
        },
      },
    ],
    beforeEnter: async () => {
      await loadModuleLanguageFileAsync('reports');
    },
  },
  {
    path: '/permissions',
    name: 'admin.permissions',
    meta: { isAngular: true },
    component: BasePlaceHolderView,
  },
  {
    path: '/roles',
    components: {
      TopBar: NotificationsTopBar,
    },
    beforeEnter: async () => {
      await loadModuleLanguageFileAsync('notifications');
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        name: 'admin.roles',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/data_exports',
    name: 'admin.dataExports',
    meta: { isAngular: true },
    component: BasePlaceHolderView,
  },
  {
    path: '/device_management',
    name: 'admin.deviceManagement',
    component: BasePlaceHolderView,
    meta: { isAngular: true },
    children: [
      {
        path: ':state',
        name: 'admin.deviceManagement.state',
        component: BasePlaceHolderView,
      },
    ],
  },
];

export const allowedAdminRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];

  if (authContext.isImpersonated()
    || (authContext.getFeatureToggle('clientTypesUi') && authContext.isSuperuser())) {
    allowedRoutes.push('admin.clientTypes');
  }

  if (authContext.userHasPermission('manageRoles')) {
    allowedRoutes.push('admin.roles');
  }

  if (authContext.userHasPermission('manageClients')) {
    allowedRoutes.push('admin.permissions');
  }

  if (authContext.userHasPermission('manageServices')) {
    allowedRoutes.push('admin.groupServices');
  }

  if (authContext.userHasPermission('manageProvidedServices')) {
    allowedRoutes.push('admin.services');
  }

  if (authContext.getFeatureToggle('dailyDataExports')
    && authContext.isSuperuser()) {
    allowedRoutes.push('admin.dataExports');
  }

  if (authContext.getFeatureToggle('eventOverview')) {
    allowedRoutes.push('admin.eventOverview');
  }

  if (authContext.getFeatureToggle('deviceManagement')
    && authContext.userHasPermission('manageDevices')) {
    allowedRoutes.push('admin.deviceManagement', 'admin.deviceManagement.state');
  }

  if (authContext.isSuperuser()) {
    allowedRoutes.push(
      'admin.integrations',
      'admin.integration-terms', // TODO needed - this is controller route?
    );
    if (authContext.getFeatureToggle('dataRetention') && authContext.isSuperuser()) {
      allowedRoutes.push(
        'admin.data_retention',
        'admin.data_retention.pending',
        'admin.data_retention.deleted',
        'admin.data_retention.report',
      );
    }
  }

  if ((authContext.isSuperuser() || authContext.currentUser.role === 'carer')) {
    allowedRoutes.push(
      'admin.reports',
      'admin.reports.new',
    );
  }

  return allowedRoutes;
};
