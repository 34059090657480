import { computed } from 'vue';
import { BaseInstance, SkinInstance, EventPwsInstance } from '@/_shared/types/NourishInstance';
import { safeTranslate } from '@/_shared/translations/i18n';
import use from '@/_shared/compositionApi';

const { translate } = use.helpers();

export default function useImageGalleryInstanceInfo(instance: BaseInstance, totalImages?: number) {
  const instanceType = computed(() => instance.instanceType);

  const instanceDetails = computed(() => {
    if (instanceType.value === 'skin') {
      return {
        info: safeTranslate(`datasets.${(instance as SkinInstance).metadata.location}`),
        totalImagesText: translate('image_gallery.total_images_info_wound', { count: totalImages }),
        showReportButton: true,
      };
    }
    if (instanceType.value === 'event_pws') {
      const eventTerm = safeTranslate(`datasets.${instance.metadata.specificType}`);
      return {
        info: safeTranslate(`datasets.${(instance as EventPwsInstance).metadata.severity}`),
        totalImagesText: translate(
          'image_gallery.total_images_info_event',
          {
            count: totalImages,
            event_term: eventTerm,
          },
        ),
        showReportButton: false,
      };
    }
    return {
      info: '',
      totalImagesText: '',
      showReportButton: false,
    };
  });

  const instanceName = computed(() => safeTranslate(`datasets.${instance.metadata.specificType}`));
  const instanceInfo = computed(() => instanceDetails.value.info);
  const totalImagesText = computed(() => instanceDetails.value.totalImagesText);
  const displayReportButton = computed(() => instanceDetails.value.showReportButton);

  return {
    instanceType,
    totalImagesText,
    displayReportButton,
    instanceName,
    instanceInfo,
  };
}
