import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-image-gallery-column" }
const _hoisted_2 = { class: "v-image-gallery-row" }
const _hoisted_3 = {
  class: "v-image-gallery-title",
  "data-id": "image-gallery-instance-name"
}
const _hoisted_4 = {
  class: "v-image-gallery-text-2",
  style: {"padding-left":"55px"}
}
const _hoisted_5 = { "data-id": "image-gallery-instance-location" }
const _hoisted_6 = { "data-id": "image-gallery-instance-time-range" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

import { BaseInstance } from '@/_shared/types/NourishInstance';
import { BaseIcon } from '@/_shared/components';
import useImageGalleryInstanceInfo from '@/_shared/Composables/useImageGalleryInstanceInfo';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceInfo',
  props: {
    instance: {},
    firstInteractionDate: {},
    lastInteractionDate: {}
  },
  setup(__props: any) {

const props = __props;

const { instanceName, instanceInfo } = useImageGalleryInstanceInfo(props.instance);

const formatDate = (date: string) => new Date(date).toLocaleDateString();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(BaseIcon), {
        name: 'v-icon-circled_logo',
        compact: true,
        "foreground-color": 'white',
        "background-color": 'black',
        "no-hover-background": "",
        noHover: "",
        size: "2x",
        height: '40px',
        width: '40px',
        fontSize: "24px"
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(instanceName)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(instanceInfo)), 1),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.firstInteractionDate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(formatDate(_ctx.firstInteractionDate)), 1))
          : _createCommentVNode("", true),
        (_ctx.lastInteractionDate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, " - " + _toDisplayString(formatDate(_ctx.lastInteractionDate)), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})